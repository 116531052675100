import Page from "../interfaces/Page";
import Home from "../pages/Home";
import Info from "../pages/Info";
import MainGame from "../pages/MainGame";
import TypingGame from "../pages/TypingGame";

const pages: Page[] = [
  Home,
  MainGame,
  TypingGame,
  Info,
];

export default pages;