import Game from "./Game";

class TextGame extends Game {
    
  public checkAnswer(params: {answer: string}): boolean {
    const prevAns = this._questions[this._currentQuestionIndex-1].answer;
    
    if(typeof prevAns !== 'string') return false;
    
    return params.answer.toLowerCase() !== prevAns.toLowerCase();
  }

}

export default TextGame;