import { generateRandomSeed } from "../utils/Utils";

function EndScreen(props: {score: number, gamePath: string, seed: string}) {
  return (
    <div className="d-flex align-items-center flex-column mx-auto text-center center-content" style={{maxWidth: "500px"}}>
      <div className="blue-box shadow w-100">
        <h1 className="lost-message">Hai perso!</h1>
        <p className="fs-4">I gamberoni hanno preso il sopravvento!</p>
        <p className="fs-5">Il tuo punteggio finale: <span className="text-warning fw-bold">{props.score}</span></p>

        <div className="d-flex gap-2 justify-content-center">
          <a href={props.gamePath + "?seed=" + props.seed} className="retry-btn text-decoration-none">Riprova</a>
          <a href={props.gamePath + "?seed=" + generateRandomSeed(5)} className="new-game-btn text-decoration-none">Nuova Partita</a>        
        </div>
      </div>
    </div>
  );
}

export default EndScreen;