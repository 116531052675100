function PopUp(props: {affectedQuestion: string, correctAnswer: boolean | string, onClose: () => void}): JSX.Element {
  return (
    <div className="popup">
      <div className="popup-content">
        <h1>Attenzione!</h1>
        <p>La domanda: {props.affectedQuestion}</p>
        <p>La risposta corretta era:&nbsp;
          {
            typeof props.correctAnswer === 'boolean' ? 
            (props.correctAnswer ? 'Vero' : 'Falso') : 
            props.correctAnswer
          }
        </p>
        <button className="green-btn w-100 p-2" onClick={props.onClose}>Ok</button>
      </div>
    </div>
  );
}

export default PopUp;