import logo from "../assets/imgs/logo.png";
import { Link } from "react-router-dom";
import pages from "../utils/Pages";
import { useState } from "react";

function Navbar(): JSX.Element {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const pagesToShow = pages.filter(page => page.showInNavbar);

  return (
    <div className="position-absolute top-0 start-0" onMouseEnter={() => setIsNavbarOpen(true)} onMouseLeave={() => setIsNavbarOpen(false)} style={{ zIndex: 1 }}>
      <div className="d-flex justify-content-center align-items-center flex-column gap-4 m-3">
        <Link to="/">
          <img id="logo" src={logo} alt="logo" width="150px" />
        </Link>
        {
          pagesToShow.map(page => isNavbarOpen &&
            <Link to={page.path} className="blue-box py-2 px-3 flex-fill text-decoration-none text-center text-white nav-btn" style={{ borderRadius: "30px" }}>
              <i className={page.icon + " fs-3"}></i>
            </Link>
          )
        }
      </div>
    </div>
  );
}

export default Navbar;