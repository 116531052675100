import { Link } from "react-router-dom";
import pages from "../utils/Pages";
import Page from "../interfaces/Page";
import { useState } from "react";
import { generateRandomSeed } from "../utils/Utils";
import GameMode from "../interfaces/GameMode";

function Render(): JSX.Element {
  const [seed, setSeed] = useState(generateRandomSeed(5));

  const pagesToShow = pages.filter(page => page.showInHome) as GameMode[];

  return (
    <div className="base-page">
      <h1 className="text-center my-5 fw-bold" style={{ fontFamily: "Pacifico" }}>Seleziona la modalità</h1>
      <div className="d-flex justify-content-center my-5">
        {
          pagesToShow.map(page =>
            <div className="blue-box text-white mx-2">
              <h2 className="fw-bold text-center">{page.title}</h2>
              <div className="white-box text-black my-3">{page.gamemodeShortDescription}</div>
              <input type="text" placeholder="Seed" className="form-control" value={seed} onChange={(e) => setSeed(e.target.value)} maxLength={5} />
              <Link to={page.path + "?seed=" + seed} className="btn btn-light mt-3 w-100 border">Gioca</Link>
            </div>
          )
        }
      </div>
    </div>
  );
}

const Home: Page = {
  title: 'Home',
  description: 'Welcome to the Home page!',
  icon: 'bi bi-house-fill',
  path: '/',
  showInNavbar: true,
  showInHome: false,
  element: Render,
}

export default Home;