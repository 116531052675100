import pages from './utils/Pages';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';

function App() {
  return (
    <BrowserRouter>
      <Navbar />

      <Routes>
        { pages.map(page => <Route path={page.path} element={<page.element/>} />) }
      </Routes>
    </BrowserRouter>
  );
}

export default App;
