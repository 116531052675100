import Game from "./Game";

class TrueFalseGame extends Game {
    
  public checkAnswer(params: {answer: boolean}): boolean {
    return params.answer !== this._questions[this._currentQuestionIndex-1].answer;
  }

}

export default TrueFalseGame;